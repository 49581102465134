import React from 'react'
import * as styles from './layout-splash.module.css'

const LayoutSplash: React.FC = ({ children }) => {
  return (
    <div className={styles.root}>
      <main className={styles.content}>{children}</main>
    </div>
  )
}

export default LayoutSplash
