import { Link } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NavGoHome: React.FC = () => {
  const { t } = useTranslation()

  return <Link to="/">{t('nav.go_home')}</Link>
}

export default NavGoHome
