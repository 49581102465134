import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LayoutSplash from '../packages/layout/layout-splash'
import NavGoHome from '../packages/nav/nav-go-home'
import Seo from '../packages/seo/seo'

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutSplash>
      <Seo title={t('error.page_not_found')} />
      <h1>{t('error.page_not_found')}</h1>
      <p>{t('error.page_not_found.description')}</p>
      <p>
        <NavGoHome />
      </p>
    </LayoutSplash>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFoundPage
